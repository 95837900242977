.page-padding {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  position: relative;
}

.padding-vertical {
  padding-left: 0;
  padding-right: 0;
}

.padding-horizontal {
  padding-top: 0;
  padding-bottom: 0;
}

.padding-xlarge {
  padding: 64px;
}

.padding-huge {
  padding: 128px;
}

.margin-top-xxlarge {
  margin-top: 96px;
}

.container-large {
  max-width: 1184px !important;
  margin: 0 auto;
}

.w-inline-block {
  display: inline-block;
  max-width: 100%;
}

.text-size-medium {
  font-size: 13px;
  font-weight: 400;
  line-height: 1.4;
  margin-bottom: 0;
  padding: 0;
  text-decoration: none;
  color: #000
}

.text-size-line {
  line-height: 15px;
}

.text-title-font {
  font-family: 'BadMofo';
}

.text-color-white {
  color: #fff;
}

.heading-large {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.2;
  text-align: center;
}

.heading-xlarge {
  font-family: 'BadMofo';
  font-size: 3.2rem;
  font-weight: 300;
  line-height: 1.2;
  text-align: center;
}

.margin-medium {
  margin: 96px;
}

.margin-bottom-medium {
  margin-bottom: 64px;
}

.margin-top-medium {
  margin-top: 64px;
}

.margin-small {
  margin: 32px;
}

.margin-xxsmall {
  margin: 8px;
}

.text-size-medium.margin-botton.margin-xxsmall {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
}

.flex-horizontal {
  display: flex;
}

.flex-horizontal.justify {
  justify-content: space-between;
  position: relative;
  z-index: 1;
}

.button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10px;
  margin-top: 50px;
}

.button-large {
  align-items: center;
  border: 1px solid #ffe500;
  border-radius: 4px;
  color: #ffe500;
  background-color: #000;
  display: flex;
  font-size: 24px;
  line-height: 1.2;
  font-weight: 700;
  /* height: 48px; */
  justify-content: center;
  /* min-width: 105px;
  max-width: 250px; */
  padding: 20px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: color .25s cubic-bezier(.165,.84,.44,1);
  transition: border-color .25s cubic-bezier(.165,.84,.44,1);
  cursor: pointer;
}

.button-large:hover {
  color: #3de8a0; 
  border-color: #3de8a0;
  opacity: 1;
}

.button-modal {
  width: 100%;
  align-items: center;
  border-radius: 4px;
  color: #ffe500;
  background-color: #000;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  height: 46px;
  justify-content: center;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color .25s cubic-bezier(.165,.84,.44,1);
  cursor: pointer;
}

.message-container {
  align-items: center;
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  margin: 10px 0;
  margin-top: 25px;
  min-height: 46px;
  min-width: 105px;
  padding: 8px 12px;
  width: 100%;
  word-break: break-all;
}

.message-text {
  font-size: 12px;
}

.text-field {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  color: #212121;
  font-weight: 400;
  height: 42px;
  margin-bottom: 0;
  margin-top: 8px
}

.w-input, .w-select {
  background-color: #fff;
  display: block;
  font-size: 14px;
  line-height: 1.428571429;
  padding: 8px 12px;
  width: 100%;
}

input {
  outline: none;
}

/* #ffe500
#fc4503
#ff9700
#6a0094 */
/* header */

.section_header {
  width: 100%;
  min-height: 100vh;
  height: 2715px;
  background: linear-gradient(rgba(0, 0, 0, 0.24) 0%, rgba(0, 0, 0, 0) 50%) 0% 0% / cover, linear-gradient(90deg, rgba(0, 0, 0, 0.48) 0%, rgba(0, 0, 0, 0) 50%), linear-gradient(0deg, rgba(0, 0, 0, 0.16), rgba(0, 0, 0, 0.16)), url(../images/PosterHalveillon_MB.png) top center no-repeat;
  background-size: cover !important;
}

.nav-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  width: fit-content;
  margin: 0 auto;
  padding: 5px 10px;
  background-color: #000;
  /* background-color: #000;
  padding: 20px 0;
  border-radius: 45px;
  border-color: #ff9700;
  border-bottom-width: 6px;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  border-style: solid; */
}

.menu-item a {
  text-transform: uppercase;
  color: #bca900;
  font-size: 3rem;
  font-family: 'BadMofo';
  font-weight: 300;
}

/* header */

/* carousel */

.section_carousel {
  background-color: #3a0051;
  color: #fff;
}

.carousel-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 20px;
  column-gap: 20px;
  width: 100%;
}

.carousel-card {
  width: 100%;
  height: 550px;
  position: relative;
  /* padding: 20px; */
  background-color: #fff;
  color: #000;
  border-style: solid;
  border-color: #000;
  border-width: 0 15px 15px 0;
}

.carousel-card .social-span {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
  color: #000;
}

.carousel-card .info {
  width: 100%;
  position: absolute;
  bottom: 60px;
  text-align: center;
}

.carousel-card .info div {
  background-color: #a423b0;
  width: max-content;
  padding: 10px;
  margin: 20px auto;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  border-style: solid;
  border-color: #240a50;
  border-width: 0 6px 6px 0;
}

.slide-items .slick-next {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 40%;
  right: 0;
  z-index: 99;
  width: 48px;
  height: 64px;
  background: #3de8a0;
}

.slide-items .slick-next:hover {
  background: #3de8a0;
}

.slide-items .slick-prev {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 40%;
  left: 0;
  z-index: 99;
  width: 48px;
  height: 64px;
  background: #3de8a0;
}

.slide-items .slick-prev:hover {
  background: #3de8a0;
}

.slide-items .slick-list {
  padding: 0 !important;
}

.slide-items .slick-list .slick-track {
  margin-left: 0 !important;
}

.slide-items .slick-slide .container-card {
  padding-right: 24px;
}

/* carousel */

/* faq */

.section_faq {
  background-color: #3a0051;
}

.card-faq-wrapper {
  padding-bottom: 15px;
  padding-top: 15px;
}

.card-faq {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow: hidden;
  padding-bottom: 0;
  padding-top: 0;
}

.card-faq-wrapper.is-expanded .card-faq-text {
  max-height: 300px;
}

.card-faq-wrapper.is-expanded .icon-seta {
  transform: rotate(90deg);
}

.card-faq-wrapper .card-faq-text {
  max-height: 0;
  overflow: hidden;
  transition: max-height .3s ease-in-out;
}

.card-faq-wrapper .icon-seta {
  transition: transform .4s ease-in-out;
}

.card-faq-wrapper .card-faq-text .card-faq-content {
  padding-top: 10px;
  margin-left: 24px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}

/* faq */

/* infoCards */

.section_infoCards {
  color: #000;
  background-color: #ff9700;
}

/* .section_infoCards h2 {
  font-weight: 700;
  color: #ff9700;
  text-transform: uppercase;
  -webkit-text-stroke-width: 0.6px;
  stroke-width: 0.6px;
  -webkit-text-stroke-color: #000;
  stroke: #000;
  text-shadow: 3px 0px 0px rgba(255, 255, 255, 0.9);
} */

.infoCards-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

.info-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  background-color: transparent;
  border: 2px solid #000;
  color: #000;
  border-radius: 30px;
  padding: 40px 20px;
  text-align: center;
}

.info-card .icon {
  width: 50px;
  height: 50px;
}

.info-card .text {
  text-transform: uppercase;
}

/* infoCards */

/* bonusCards */

.section_bonusCards {
  background-color: #3a0051;
  color: #fff;
}

.bonusCards-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.bonus-card {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  width: 100%;
  height: 200px;
  /* padding: 6px; */
  background-color: #a423b0;
  border-radius: 30px;
}

.bonus-card .text {
  padding: 20px;
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.2rem;
  text-transform: uppercase;
}

/* .bonus-card .bonus-image {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
} */


/* bonusCards */

/* schedule */

.section_schedule {
  background-color: #fff;
  color: #ff9700;
}

.schedule-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 20px;
  width: 100%;
  padding: 30px 40px;
  background-color: #ff9700;
  border-radius: 30px;
}

.schedule-wrapper .schedule-item {
  display: flex;
  align-items: center;
  width: fit-content;
  color: #fff;
  font-size: 13px;
  line-height: 1.2;
  padding-bottom: 5px;
  margin-bottom: 10px;
  border-bottom: 1px solid #fff;
}

.schedule-wrapper .schedule-item img {
  width: 25px;
}

.schedule-wrapper .schedule-item .schedule-hours {
  color: #3a0051;
  font-weight: 700;
  margin-left: 5px;
}

/* schedule */

/* feedbacks */

.section-feedbacks {
  background-color: #000;
}

.column-image {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 800px;
  margin-left: 16px;
}

.animation-image-column1 {
  background-image: url("../images/Column-1.png");
  background-size: cover;
  animation: infinite-up 100s linear infinite;
}

.animation-image-column2 {
  background-image: url("../images/Column-2.png");
  background-size: cover;
  animation: infinite-down 100s linear infinite;
}
.animation-image-column3 {
  background-image: url("../images/Column-3.png");
  background-size: cover;
  animation: infinite-up 100s linear infinite;
}
.animation-image-column4 {
  background-image: url("../images/Column-4.png");
  background-size: cover;
  animation: infinite-down 100s linear infinite;
}

@keyframes infinite-up {
  0% {
    background-position: 50% 0;
  }
  100% {
    background-position: 50% -1000px;
  }
}

@keyframes infinite-down {
  0% {
    background-position: 50% 0;
  }
  100% {
    background-position: 50% 1000px;
  }
}

.column-image.column-image-4 {
  margin-right: 16px;
}

.cover-image {
  position: absolute;
  width: 100%;
}

.cover-image.cover-image-2 {
  bottom: 0px;
  display: block;
  height: 1508px;
}

.cover-image.cover-image-4 {
  bottom: 0px;
  height: 1509px;
}

.cover-image.cover-image-1 {
  top: 0px;
  height: 1512px;
}

.cover-image.cover-image-3 {
  top: 0px;
  height: 1526px;
}

/* feedbacks */

/* footer */

.section_footer {
  width: 100%;
  background-color: #000;
}

.footer-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-wrapper-logo {
  text-align: center;
  width: 136px;
}

.footer-addresses {
  text-align: right;
  width: 185px;
}

.footer-links {
  display: flex;
  gap: 10px;
}

/* footer */

/* stickbar */

.stickbar-section {
  background-color: #000;
  display: flex;
  justify-content: center;
  padding-bottom: 16px;
  padding-top: 16px;
  font-size: 19px;
}

/* stickbar */

/* modal */

.modal_section {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  width: 480px;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.modal-close {
  background: none;
  border: none;
  color: #333;
  font-size: 16px;
  cursor: pointer;
  float: right;
}

.modal-content {
  margin-top: 10px;
}

/* modal */


@media screen and (max-width: 425px) {
  .footer-wrapper {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    flex-direction: column;
  }

  .footer-addresses {
    text-align: center;
  }

  .section_header {
    height: 100vh;
  }
}

@media screen and (max-width: 767px) {
  .padding-xlarge {
    padding: .5rem;
  }
}

@media screen and (max-width: 1024px) {
  .button-large {
    font-size: 14px;
    padding: 10px;
  }

  .stickbar-section {
    font-size: 13px;
  }
}
